import React from 'react';
import './Footer.css';
// import { Button } from './Button'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faYoutube, faTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons';


function Footer() {
  return (
    <div className='footer-container'>


      <div className='footer-links'>
        <div className='footer-link-wrapper'>
          <div className='footer-link-items'>
            <h2>About Me</h2>
            <Link to='/'>Who am I?</Link>
            <Link to='/research'>Research</Link> 
            <a href="https://www.nearbycomputing.com/" target="_blank" rel="noopener noreferrer">NearbyComputing</a>
          </div>
          <div className='footer-link-items'>
            <h2>Contact</h2>
            <Link to='/'>allesaangort@gmail.com</Link>
          </div>
        </div>
        <div className='footer-link-wrapper'>
          <div className='footer-link-items'>
            <h2>Social Media</h2>
            <a href="https://www.linkedin.com/in/bjdg/" target="_blank" rel="noopener noreferrer">LinkedIn</a>
            <a href="https://www.youtube.com/@borgnewsbot" target="_blank" rel="noopener noreferrer">BorgNews</a>
          </div>
        </div>
      </div>
      <section className='social-media'>
        <div className='social-media-wrap'>
          <div className='footer-logo'>
            <Link to='/' className='social-logo'>
              BJDG
              <i className='fab fa-typo3' />
            </Link>
          </div>
          <small className='website-rights'> CoderBurnt © 2023</small>


          {/* Social Icons list */}
          <div className='social-icons'>
            <Link className='social-icon-link facebook' to='/' target='_blank' aria-label='Facebook'>
              <FontAwesomeIcon icon={faFacebookF} />
            </Link>
            <Link className='social-icon-link instagram' to='/' target='_blank' aria-label='Instagram'>
              <FontAwesomeIcon icon={faInstagram} />
            </Link>
            <Link className='social-icon-link youtube' to='/' target='_blank' aria-label='Youtube'>
              <FontAwesomeIcon icon={faYoutube} />
            </Link>
            <Link className='social-icon-link twitter' to='/' target='_blank' aria-label='Twitter'>
              <FontAwesomeIcon icon={faTwitter} />
            </Link>
            <Link className='social-icon-link linkedin' to='/' target='_blank' aria-label='LinkedIn'>
              <FontAwesomeIcon icon={faLinkedin} />
            </Link>
          </div>



        </div>
      </section>
    </div>
  );
}

export default Footer;
