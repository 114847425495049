import React from 'react';
import './Cards.css';
import CardItem from './CardItem';

function Cards() {
  return (
    <div className='cards'>
      <h1>Highlighted Research: The Algorithms That Fuel Our Innovations</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
                src='./all_media_pages/Research/img/retnet.jpg'
                text='Retentive Network: A Successor to Transformer for Large Language Models'
                label='Bye Transformers'
                path='https://arxiv.org/pdf/2307.08621.pdf'
                isExternal={true}
              />
              <CardItem
                src='./all_media_pages/Research/img/informer.png'
                text='Informer: Beyond Efficient Transformer for Long Sequence
                Time-Series Forecasting'
                label='The Algorithm Behind my Latest Paper'
                path='https://arxiv.org/pdf/2012.07436.pdf'
                isExternal={true}
              />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src='./all_media_pages/Research/img/transformer.png'
              text='Attention Is All You Need'
              label='The Legendary Transformer paper'
              path='https://arxiv.org/pdf/1706.03762.pdf'
              isExternal={true}
            />
            <CardItem
              src='./all_media_pages/Research/img/longnet.jpg'
              text='LongNet: Scaling Transformers to 1,000,000,000 Tokens'
              label='Infinite Scaling'
              path='https://arxiv.org/pdf/2307.08621.pdf'
              isExternal={true}
            />
            <CardItem
              src='./all_media_pages/Research/img/XAI_LLM.png'
              text='Language Nodels can explain Neurons in Language Models'
              label='XAI for LLMs?'
              path='https://openaipublic.blob.core.windows.net/neuron-explainer/paper/index.html#sec-intro'
              isExternal={true}
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;
