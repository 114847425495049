import React from 'react';
import '../../App.css';
// import { Button } from '../Common/Button';
import '../Common/HeroSection.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown,} from '@fortawesome/free-solid-svg-icons';



function HeroSection() {
  
  return (
    <div className='hero-container'>
      <video src='./all_media_pages/Projects/videos/welding_car.mp4' autoPlay loop muted />
      <h1>My Projects</h1>
      <p className="center-text"><i>"The Man, The Myth, The Mancave"</i></p>
      <div className='hero-btns'>


        {/* <Button
          className='btns'
          buttonStyle='btn--primary'
          buttonSize='btn--large'
          onClick={() => window.open('https://www.linkedin.com/in/bjdg/', '_blank')}
        >
          Latest Publication  <FontAwesomeIcon icon={faFile} />
        </Button> */}
        <FontAwesomeIcon icon={faArrowDown} className='scroll-down-arrow' />

      </div>
    </div>
  );
}

export default HeroSection;
