import React from 'react';
import CardsPubs from './Cards_Publications';
import CardsFeatured from './Cards_Featured';
import HeroSection from './HeroSection';

function Research() {
  return (
    <>
      <HeroSection />
      <CardsPubs />
      <CardsFeatured />
    </>
  );
}

export default Research;