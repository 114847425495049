import React from 'react';
import { Link } from 'react-router-dom';

function CardItem(props) {
  const isExternal = props.isExternal || false;

  const content = (
    <>
      <figure className='cards__item__pic-wrap' data-category={props.label}>
        <img className='cards__item__img' alt='Travel' src={props.src} />
      </figure>
      <div className='cards__item__info'>
        <h5 className='cards__item__text'>{props.text}</h5>
      </div>
    </>
  );

  return (
    <li className='cards__item'>
      {isExternal ? (
        <a href={props.path} target="_blank" rel="noopener noreferrer" className='cards__item__link'>
          {content}
        </a>
      ) : (
        <Link to={props.path} className='cards__item__link'>
          {content}
        </Link>
      )}
    </li>
  );
}

export default CardItem;
