import React from 'react';
import Navbar from './components/Common/Navbar';
import Footer from './components/Common/Footer';
import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import ScrollToTop from './components/Common/ScrollToTop';

import Home from './components/Home/Home';
import Research from './components/Research/Research';
import Projects from './components/Projects/Projects';
import Journey from './components/Journey/Journey';
import Kitesurfing from './components/Kitesurfing/Kitesurfing';

function App() {
  return (
    <>
      <Router>
        <ScrollToTop />
        <Navbar />
          <Switch>
            <Route path='/' exact component={Home} />
            <Route path='/research' component={Research} />
            <Route path='/projects' component={Projects} />
            <Route path='/journey' component={Journey} />
            <Route path='/kitesurfing' component={Kitesurfing} />
          </Switch>
        <Footer />
      </Router>
      
    </>
  );
}

export default App;

