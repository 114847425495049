import React from 'react';
import '../Common/InfoSection.css';

function InfoSection() {

  return (
    <div>
        <div className='info-section'>
          <br /><br />
          <img src="./all_media_pages/Home/img/Transformer_Architecture.png"  alt="First Section" className='info-img' />
          <br /><br />
          <div className='info-text'>
            <h2>Hi, I'm Berend! 👋</h2><br />

            
            <p>
              Exciting news! My latest research article, "Forecasting Trends in Cloud-Edge Computing: Unleashing the Power of Attention Mechanisms," has been published in IEEE Communications Magazine.
              <br /><br /></p>
            <p>
              We introduce 'informers,' a novel class of algorithms using sparse attention mechanisms for efficient time-series forecasting in cloud-edge environments. Our experiments show significant improvements in prediction accuracy and computational efficiency compared to traditional models.
              <br /><br /></p>
            <p>
              The paper explores practical applications including workload forecasting, resource allocation, and failure prediction in cloud-edge domains. These findings could lead to more efficient and sustainable cloud-edge computing infrastructures.
              <br /><br /></p>
            <p>
              This publication aligns with my PhD research and start-up work in AI and cloud/edge orchestration. Interested in discussing further? Feel free to reach out!
              <br /><br /><br />
            </p>


          </div>
        </div>

        {/* Add more sections directly here */}

    </div>
  );
}

export default InfoSection;

