import React from 'react';
import '../Common/JourneyCards.css';

function JourneyCards() {


  // List of tech stack items
  const techStack = [
    { name: 'C++', logo: './all_media_pages/Journey/img/tech-flags/Cpp.png' },
    { name: 'Docker', logo: './all_media_pages/Journey/img/tech-flags/Docker.png' },
    { name: 'Google Cloud', logo: './all_media_pages/Journey/img/tech-flags/Gcloud.png' },
    { name: 'GitHub', logo: './all_media_pages/Journey/img/tech-flags/GitHub.png' },
    { name: 'Helm', logo: './all_media_pages/Journey/img/tech-flags/helm-logo.png' },
    { name: 'Java', logo: './all_media_pages/Journey/img/tech-flags/Java.png' },
    { name: 'Kubernetes', logo: './all_media_pages/Journey/img/tech-flags/KuberNetes.png' },
    { name: 'LaTeX', logo: './all_media_pages/Journey/img/tech-flags/latex.png' },
    { name: 'Linux', logo: './all_media_pages/Journey/img/tech-flags/Linux.png' },
    { name: 'MatLab', logo: './all_media_pages/Journey/img/tech-flags/MatLab.png' },
    { name: 'NumPy', logo: './all_media_pages/Journey/img/tech-flags/numpy.png' },
    { name: 'NVIDIA CUDA', logo: './all_media_pages/Journey/img/tech-flags/nvidia_cuda.png' },
    { name: 'Pandas', logo: './all_media_pages/Journey/img/tech-flags/pandas.png' },
    { name: 'Python', logo: './all_media_pages/Journey/img/tech-flags/python.png' },
    { name: 'PyTorch', logo: './all_media_pages/Journey/img/tech-flags/pytorch-logo.png' },
    { name: 'Scikit-Learn', logo: './all_media_pages/Journey/img/tech-flags/scikit.png' },
    { name: 'Seldon', logo: './all_media_pages/Journey/img/tech-flags/seldon.png' },
    { name: 'Siemens NX', logo: './all_media_pages/Journey/img/tech-flags/SiemensNX.png' },
    { name: 'Simulink', logo: './all_media_pages/Journey/img/tech-flags/Simulink.png' },
    { name: 'Spark', logo: './all_media_pages/Journey/img/tech-flags/spark.png' },
    { name: 'SQL', logo: './all_media_pages/Journey/img/tech-flags/SQL.png' },
    { name: 'Tableau', logo: './all_media_pages/Journey/img/tech-flags/tableau.png' },
    { name: 'TensorFlow', logo: './all_media_pages/Journey/img/tech-flags/tensorflow.png' },
    { name: 'Ubuntu', logo: './all_media_pages/Journey/img/tech-flags/ubuntu.png' },
    { name: 'React', logo: './all_media_pages/Journey/img/tech-flags/React.png' },
  ];


  return (
    <div className='journey-cards'>
      <h2 style={{ fontSize: '3em', textAlign: 'center' }}>Tech Stack</h2> 
      <br /><br /><br />

      <div className="tech-stack-container">
        {techStack.map((item, index) => (
          <div className="tech-stack-item" key={index}>
            <img className="tech-stack-logo" src={item.logo} alt={item.name} />
            <div className="tech-stack-text">{item.name}</div>
          </div>
        ))}
      </div>

      
      <br /><br /><br />
      <h2 style={{ fontSize: '3em', textAlign: 'center' }}>Short Intro</h2> 
      <br /><br /><br />
      <div style={{ maxWidth: '800px', margin: '0 auto' }}>

        <div className="center-block">
          
          <h3 className='journey-subtitle'>Who Am I?</h3><br />
            <p>
              <strong>Berend Gort</strong>, a Research Engineer, diving deep into the realms of Machine Learning, Signal Theory and Communications. Currently, I am pursuing a Ph.D. in Applied Artificial Intelligence from the <em>Universidad Politecnica de Catalunya</em>. The subject of my research is <strong>Attention Mechanisms</strong> for <strong>Cloud/Edge Orchestration</strong>.
            </p><br />
          <h3 className='journey-subtitle'>My Academic Foundation</h3><br />
          <p>
            With dual master's degrees in <strong>Artificial Intelligence & Computer Science</strong> from Harbour.Space University and <strong>Mechanical Engineering</strong> from the Technical University of Eindhoven, I've built a solid academic foundation.
          </p><br />

          <h3 className='journey-subtitle'>Where I Work</h3><br />
          <p>
            I am putting my skills to work at <strong>NearbyComputing</strong> in Barcelona, Spain. Here, my focus is on advanced time-series prediction and ML cloud/edge orchestration.
          </p><br />

          <h3 className='journey-subtitle'>What Makes Me Unique?</h3><br />
          <p>
            My diverse academic background equips me with a unique skill set that ranges from signal theory to deep learning algorithms. I've been fortunate to publish my research in reputable conferences and contribute meaningfully to the field of deep reinforcement learning.
          </p><br />
        </div>
      </div>



      <br /><br /><br />
      <h2 style={{ fontSize: '3em', textAlign: 'center' }}>Professional Experience</h2>



      <div className='journey-container'>
        <div className='journey-wrapper'>
        
         {/* AI Research Engineer at NearbyComputing */}
        <br /><br /><br />
        <div className='journey-item'>
          <div className='journey-image'>
            <h3>AI Research Engineer</h3><br />
            <img  style={{ width: '180px', height: 'auto' }} src='./all_media_pages/Journey/img/nearbycomputing.jpg' alt='NearbyComputing' />
          </div>

            <div className='journey-info'>

              <p>After working in various industries, I realized Machine Learning requires extensive knowledge of cloud/edge computing. Currently, at NearbyComputing, I'm working on a Ph.D. in Applied Artificial Intelligence, focusing on cloud/edge orchestration. Orchestration is a fine word; our product is a Master to the Kubernetes minions. For instance, any mobile network provider is currently struggling with controlling their edge infrastructure. We are building a solution that allows them to control their edge infrastructure with a single click.
              </p><br />
              <p>
              At NearbyComputing, I get to be both a mad scientist and a real-world problem solver. In my latest paper, I used a Transformer model to predict CPU usage of 10.000 servers in a Google Cloud Cell. Basically my job right now is predicting variables in time. What a skill to have right, predicting the future?
              </p><br />
              <p>
                It's not just about foreseeing the future; it's also about keeping everything neat and organized. Ever heard of an MLFlow server? I built one of those from scratch. Think of it as a library where every book is an algorithm. This infrastrucutre allows complex collaboration with colleagues.
              </p><br />
              <p>
                And let's not forget the grand finale: the inference pipeline. Imagine a high-speed freeway where data zips around with zero traffic, thanks to NVIDIA Triton and Seldon Core. It's like the autobahn for putting machine learning models in production.
              </p><br />
              <p>
                I've got an incredible team around me, and I feel free to explore any direction I want. We're all mad scientists, trying to find the latest state-of-the-art solutions, and putting them to work.
              </p>

            </div>
            <div className='journey-info-time'>
              <div className='journey-info-date'>
                <span>March 2023 - Present</span>
              </div>
              <div className='journey-info-location'>
                <span>Barcelona, Spain</span>
                <img src='./all_media_pages/Journey/img/spainflag.webp' alt='Flag of Spain' className='flag-icon'/>
              </div>
            </div>
          </div>

        {/* AI Research Engineer at Columbia University */}
        <br /><br /><br />
        <div className='journey-item'>
          <div className='journey-image'>
            <h3>AI Research Scientist</h3><br />
            <img style={{ width: '180px', height: 'auto' }} src='./all_media_pages/Journey/img/columbia.jpg' alt='Columbia University'/>
          </div>
          <div className='journey-info'>
            
          <p>
            At AI4Finance I touched my first supercomputer. A computer worth 400.000 euro's. It was a beast, and I was in charge of it. I built a pipeline that allowed us to run 1000's of experiments in parallel. It is like having a superpower.
          </p><br />
          <p>
            Diving into the world of finance armed with machine learning is more than just number-crunching; it's like solving a complex puzzle with high stakes. In my recent work, I focused on reducing overfitting in models, slashing it by 46%. It's like to stripping away the noise to reveal the core truth.
          </p><br />
          <p>
            Sharing knowledge is as essential as gaining it. That's why I took the role of moderating the open-source code for FinRL on GitHub. The validation came when we witnessed a 214% spike in stars within a year. Currently AI4Finance is at 8000 stars and counting.
          </p><br />
          <p>
            It's about revolutionizing how we see and use machine learning in finance. We're not just adapting to the industry; we're setting out to redefine it. AI4Finance is giving away high-quality research for free, and I'm proud to be a part of it.
          </p>


          </div>
          <div className='journey-info-time'>
            <div className='journey-info-date'>
              <span>January 2022 - November 2022</span>
            </div>
            <div className='journey-info-location'>
              <span>New York, USA</span>
              <img src='./all_media_pages/Journey/img/usaflag.webp' alt='Flag of USA' className='flag-icon'/>
              {/* Add a flag image if you have one */}
            </div>
          </div>
        </div>



        {/* AI Signal Theory Research Engineer at TNO Telecommunications */}
        <br /><br /><br />
        <div className='journey-item'>
          <div className='journey-image'>
            <h3>AI Research Scientist</h3><br />
            <img style={{ width: '180px', height: 'auto' }} src='./all_media_pages/Journey/img/TNO.jpg' alt='TNO Telecommunications'/>
          </div>
          <div className='journey-info'>

          <p>
            If you thought the sky was the limit, think again. My curiosity took me beyond our atmosphere, into the realm of space communications. During my tenure at TNO Telecommunications, I plunged into the cutting-edge field of optical laser communication. Imagine this: lasers in space, zapping data across satellites at speeds you wouldn't believe—more than 1 Tbps!
            <br /><br />
          </p>
          <p>
            This isn't just sci-fi stuff; it's real and it's happening now. The tech I worked on uses high-frequency laser beams for ultra-fast, super-secure communications between satellites. And guess what? This can make our internet on Earth insanely fast too. We're talking about a 6G network that can make your current internet look like a snail.
            <br /><br />
          </p>
          <p>
            But it's not just about speed; it's about precision and reliability. I was part of a team that designed a control system to aim these lasers with pinpoint accuracy. And the results? We slashed control errors by 86%.
          </p>


          </div>
          <div className='journey-info-time'>
            <div className='journey-info-date'>
              <span>June 2019 - June 2020</span>
            </div>
            <div className='journey-info-location'>
              <span>Delft, Netherlands</span>
              <img src='./all_media_pages/Journey/img/dutchflag.webp' alt='Flag of the Netherlands' className='flag-icon'/>
            </div>
          </div>
        </div>

        {/* Robotics Research Engineer at Swiss Plasma Center, EPFL */}
        <br /><br /><br />
        <div className='journey-item'>
          <div className='journey-image'>
            <h3>Robotics Research Engineer</h3><br />
            <img style={{ width: '180px', height: 'auto' }} src='./all_media_pages/Journey/img/SPC.jpg' alt='Swiss Plasma Center'/>
          </div>
          <div className='journey-info'>

          <p>
            Now, let's take a step back and travel to the Swiss Plasma Center, where I worked in the fusion of robotics and, well, actual nuclear fusion. Nuclear fusion is like creating a mini-sun but right here on earth. It actually gets to 100 million degrees; a 3x hotter than the actual sun! It's the holy grail of energy production, and it's the future of clean energy, we only need to mine the moon for Helium-3. But that's a story for another time.
            <br /><br />
          </p>
          <p>
            There was a big rock there that they used to start the reactor. They would spin up a 300-ton rock at 6000 RPM to ignite the reactor. With a big rock I mean a flywheel. If they would pull the energy of the grid instead of using the flywheel, the whole city would go dark. That's how much energy they were using.
          <br /><br />
          </p>
          <p>
            Working on the TCV fusion reactor gives a NASA vibe. There is a 'control room' which looks exactly like the NASA headquarters for space missions. A hundred computers aligned in a big room towards a large screen with the main engineer giving commands to people of what to do. Alongside a dream team of physicists and engineers, we guided 4.5MW microwave beams to heat plasma. My job was the robotic control the mirrors that reflected all of this energy into the reactor. 
          </p>

          </div>
          <div className='journey-info-time'>
            <div className='journey-info-date'>
              <span>November 2018 - April 2019</span>
            </div>
            <div className='journey-info-location'>
              <span>Lausanne, Switzerland</span>
              <img src='./all_media_pages/Journey/img/swissflag.jpg' alt='Flag of the Netherlands' className='flag-icon'/>
            </div>
          </div>
        </div>

      <br /><br /><br />
      <h2 style={{ fontSize: '3em', textAlign: 'center' }}>Education</h2>


        {/* MSc AI & Computer Science at Harbour.Space University */}
        <br /><br /><br />
        <div className='journey-item'>
          <div className='journey-image'>
            <h3>MSc AI & Computer Science</h3><br />
            <img style={{ width: '180px', height: 'auto' }} src='./all_media_pages/Journey/img/hs.jpg' alt='Harbour.Space University' />
          </div>
          <div className='journey-info'>


            <p>
              I earned my MSc in AI & Computer Science from Harbour.Space University with a standout GPA of 9.3/10, graduating Magna Cum Laude. My work stood out, earning me a full scholarship.
            </p><br />
            <p>
              My thesis on "Deep Reinforcement Learning with Proximal Policy Optimization in a Multi-Crypto Environment" received a perfect grade of 10.0/10, showcasing my focus on cutting-edge tech.
            </p><br />
            <p>
              Coursework included high-scoring subjects like Neural Networks (9.8), Machine Learning (9.5), and Algorithms and Data Structures (8.6). These courses laid a strong foundation for my research and professional work.
            </p>


          </div>
          <div className='journey-info-time'>
            <div className='journey-info-date'>
              <span>September 2020 - December 2021</span>
            </div>
            <div className='journey-info-location'>
              <span>Barcelona, Spain</span>
              <img src='./all_media_pages/Journey/img/spainflag.webp' alt='Flag of Spain' className='flag-icon'/>
            </div>
          </div>
        </div>


        {/* MSc Mechanical Engineering Control Technology at Technical University */}
        <br /><br /><br />
        <div className='journey-item'>
          <div className='journey-image'>
            <h3>MSc Control Engineering</h3><br />
            <img style={{ width: '180px', height: 'auto' }} src='./all_media_pages/Journey/img/tue.jpg' alt='Technical University Eindhoven' />
          </div>
          <div className='journey-info'>
            <p>
              I completed my MSc in Mechanical Engineering, specializing in Control Technology, at the Technical University of Eindhoven with a GPA of 7.3/10.
            </p><br />
            <p>
              My thesis focused on minimizing torque ripple in Switched-Reluctance Motors using Repetitive Control, a subject vital for energy-efficient engineering.
            </p><br />
            <p>
              Courses included Advanced Calculus for Control (9.0), Scientific Computing for Mechanical Engineering (8.0), and Control Engineering (8.0), equipping me with a strong skill set in control systems and computational methods.
            </p>
          </div>
          <div className='journey-info-time'>
            <div className='journey-info-date'>
              <span>September 2018 - June 2020</span>
            </div>
            <div className='journey-info-location' >
              <span>Eindhoven, Netherlands</span>
              <img src='./all_media_pages/Journey/img/dutchflag.webp' alt='Flag of the Netherlands' className='flag-icon'/>
            </div>
          </div>
        </div>


        
        </div>
      </div>
    </div>
  );
}

export default JourneyCards;
