import React from 'react';
import HeroSection from './HeroSection';
import MediaGallery from './MediaGallery';

function Kitesurf() {
  return (
    <>
      <HeroSection />
      <MediaGallery />
    </>
  );
}

export default Kitesurf;