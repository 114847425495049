import React from 'react';
import '../../App.css';
import { Button } from '../Common/Button';
import './HeroSectionHome.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPlayCircle,faArrowDown} from '@fortawesome/free-solid-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';




function HeroSection() {
  
  return (

    <div className='hero-container'>

      <div className='image-wrapper'>
          <img className='right-circle-img' src='./all_media_pages/Home/img/faceshot2.jpeg' alt='Right' />
      </div>
      
      <video src='./all_media_pages/Home/videos/dna_rolling.mp4' autoPlay loop muted />
      <h2>Welcome to My Hub:</h2><br />
      <h1>Berend Jelmer Dirk Gort</h1><br /><br />
      <p>Ready to explore?</p>
      <div className='hero-btns'>


        {/* <Button
          className='btns'
          buttonStyle='btn--primary'
          buttonSize='btn--large'
          onClick={() => window.location.href='/Journey'}
        >
          Journey  <FontAwesomeIcon icon={faRoad} />
        </Button> */}

        <Button
          className='btns'
          buttonStyle='btn--primary'
          buttonSize='btn--large'
          onClick={() => window.open('https://www.linkedin.com/in/bjdg/', '_blank')}
        >
          LinkedIn  <FontAwesomeIcon icon={faLinkedin} />
        </Button>

        {/* <Button
          className='btns'
          buttonStyle='btn--primary'
          buttonSize='btn--large'
          onClick={() => window.location.href='/research'}

        >
          Research  <FontAwesomeIcon icon={faBook} />
        </Button> */}
        
        <Button
          className='btns'
          buttonStyle='btn--primary'
          buttonSize='btn--large'
          onClick={() => window.open('https://www.techrxiv.org/users/706396/articles/691754-forecasting-trends-in-cloud-edge-computing-unleashing-the-power-of-attention-mechanisms', '_blank')}
        >
          Latest Publication <FontAwesomeIcon icon={faPlayCircle} />
        </Button>

        {/* <Button
          className='btns'
          buttonStyle='btn--primary'
          buttonSize='btn--large'
          onClick={() => window.location.href='/kitesurfing'}
        >
          Kitesurfing <FontAwesomeIcon icon={faWind} />
        </Button> */}
        <br /><br /><br /><br /> <br />
        <FontAwesomeIcon icon={faArrowDown} className='scroll-down-arrow' />
        <br /><br /><br />

      </div>
    </div>
  );
}

export default HeroSection;
