import React from 'react';
import './Cards.css';
import CardItem from './CardItem';

function Cards() {
  return (
    <div className='cards'>
      <h1>Some of the stuff I make in my basement</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
                src='./all_media_pages/Projects/img/BorgNewsLogo.jpg'
                text={`BorgNews is a state-of-the-art platform that modernizes news consumption through the power of API technology and advanced AI algorithms. We aim to deliver succinct, impactful news while maximizing efficiency and reach.

                🔄 Real-Time News Updates: We use the News API to provide up-to-the-minute global news from credible sources. Stay always informed.

                🧠 Intelligent Summaries: Utilizing advanced AI models like GPT-4, we offer crisp, to-the-point article summaries. Get the gist without the fluff.

                🤖 Fully Automated Workflow: Our entire development pipeline is automated from start to finish, ensuring seamless integration, updates, and scalability.

                💼 Subject Flexibility: Currently focused on crypto news, BorgNews is designed to easily pivot to any topic area, giving you the power to stay informed on subjects that matter to you.

                🌟📰🤖 With BorgNews, we are shaping the future of news to be automated, tailored, and interactive. Come be a part of this news revolution.`}
                label='AI Crypto News'
                path='https://www.youtube.com/@borgnewsbot'
                isExternal={true}
              />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;
