import React, { useState, useRef } from 'react';
import '../../App.css';
import { Button } from '../Common/Button';
import '../Common/HeroSection.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faPlay, faPause } from '@fortawesome/free-solid-svg-icons';
// import { faLinkedin } from '@fortawesome/free-brands-svg-icons';



function HeroSection() {

  const [isPlaying, setIsPlaying] = useState(true);
  const audioRef = useRef(null);

  const toggleAudio = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };
  
  return (
    <div className='hero-container'>

      <video src='./all_media_pages/Kitesurfing/videos/cool_surfing_video.mp4' autoPlay loop muted />
      <audio ref={audioRef} autoPlay loop>
        <source src='./all_media_pages/Kitesurfing/music/push_it.mp4' type='audio/mp4' />
      </audio>


      <h1>The Art of Flight</h1>
      <p><i>"The ocean is my playground, the wind is my engine"</i></p>
      {/* <h2 className="scroll-instruction">CTRL + SCROLL</h2> */}
      <div className='hero-btns'>

      <Button
        className='btns audio-toggle-button'
        buttonStyle='btn--primary'
        buttonSize='btn--large'
        onClick={toggleAudio}
      >
        {isPlaying ? (
          <>
            <FontAwesomeIcon icon={faPause} /> Stop Music
          </>
        ) : (
          <>
            <FontAwesomeIcon icon={faPlay} /> Play Music
          </>
        )}
      </Button>
      <FontAwesomeIcon icon={faArrowDown} className='scroll-down-arrow' />


      </div>
    </div>
  );
}

export default HeroSection;
