import React from 'react';
import HeroSection from './HeroSection';
import JourneyCards from './JourneyCards';

function MyCV() {
  return (
    <>
      <HeroSection />
      <JourneyCards />
    </>
  );
}

export default MyCV;