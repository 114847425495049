import React from 'react';
import '../../App.css';
import { Button } from '../Common/Button';
import './Research';
import { FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faFile, faArrowDown} from '@fortawesome/free-solid-svg-icons';



function HeroSection() {
  
  return (
    <div className='hero-container'>
      <video src='./all_media_pages/Research/videos/robot_vs_man.mp4' autoPlay loop muted />
      <h1>My Research</h1>
      <p className="center-text"><i>"Did you know there are more airplanes in the sea than submarines in the sky?</i></p>
      <div className='hero-btns'>


        <Button
          className='btns'
          buttonStyle='btn--primary'
          buttonSize='btn--large'
          onClick={() => window.open('https://arxiv.org/pdf/2209.05559.pdf', '_blank')}
        >
          Latest Publication  <FontAwesomeIcon icon={faFile} />
        </Button>
        <FontAwesomeIcon icon={faArrowDown} className='scroll-down-arrow' />

      </div>
    </div>
  );
}

export default HeroSection;
