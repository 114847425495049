import React from 'react';
import './MediaGallery.css';

function MediaGallery() {
  const imageContext = require.context('../../../public/all_media_pages/Kitesurfing/img', false, /\.(jpg|jpeg)$/);
  const images = imageContext.keys().map(imageContext);
  
  const videoContext = require.context('../../../public/all_media_pages/Kitesurfing/videos', false, /\.(mp4|mov)$/);
  const videos = videoContext.keys().filter(key => key !== './cool_surfing_video.mp4').map(videoContext);
  
  
  const handleVideoOrientation = (video) => {
    if (video) {
      video.addEventListener('loadedmetadata', function() {
        if (video.videoHeight > video.videoWidth) {
          video.classList.add("vertical-video");
        } else {
          video.classList.remove("vertical-video");
        }
      });
    }
  };

  const allMedia = [
    ...images.map(src => ({ type: 'image', src })), 
    ...videos.map(src => ({ type: 'video', src }))
  ];

  allMedia.sort(() => Math.random() - 0.4);

  return (
    <div className="media-gallery">
      <br /><br />
      <div className="mixed-media-container">
        {allMedia.map((media, index) => (
          media.type === 'image' ? (
            <img key={index} src={media.src} alt="" className="gallery-image" />
          ) : (
            <video 
              ref={(videoElement) => handleVideoOrientation(videoElement)} 
              key={index} 
              controls 
              autoPlay 
              loop 
              muted 
              className="gallery-video"
            >
              <source src={media.src} type={`video/${media.src.split('.').pop()}`} />
            </video>
          )
        ))}
      </div>
    </div>
  );
}

export default MediaGallery;