import React from 'react';
import './Cards.css';
import CardItem from './CardItem';

function Cards() {
  return (
    <div className='cards'>
      <h1>Spotlight on Published Works</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>

            <CardItem
                src='./all_media_pages/Research/img/drlcrypto.png'
                text='Deep Reinforcement Learning for Cryptocurrency Trading: Practical Approach to
                Address Backtest Overfitting'
                label='AAAI 2021'
                path='https://arxiv.org/pdf/2209.05559.pdf'
                isExternal={true}

              />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;
